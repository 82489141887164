.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .contact-info-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
  }
  
  .contact-info-left,
  .contact-info-right {
    flex: 1;
    padding: 10px;
  }
  
  .contact-info-left {
    border-right: 1px solid #ddd;
  }
  
  .contact-info-right {
    padding-left: 20px;
  }
  
  .contact-info-card h2 {
    margin-top: 0;
  }
  
  .contact-info-card p,
  .contact-info-card ul {
    margin-bottom: 15px;
  }
  
  .map-container {
    width: 100%;
    max-width: 1200px;
  }
  
  .map-container h2 {
    text-align: center;
    margin-bottom: 10px;
  }
  