/* General styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .container {
    width: 90%;
    margin: 0 auto;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .footer-logo h1 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-links li {
    margin: 10px 0;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-social {
    display: flex;
    gap: 10px;
  }
  
  .social-icon {
    color: #fff;
    text-decoration: none;
  }
  
  .social-icon:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
    font-size: 0.9em;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
    
    .footer-logo, .footer-links, .footer-social {
      margin-bottom: 20px;
      text-align: center;
    }
  }
  