/* Gallery.css */

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between images */
}

.gallery-item {
  width: 400px; /* Adjust the width as per your design */
  height: 400px; /* Adjust the height as per your design */
  overflow: hidden; /* Ensures images don't overflow out of the container */
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images maintain aspect ratio and fill the container */
  border-radius: 10px; /* Optional: gives rounded corners */
}
