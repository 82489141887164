.home-container {
    position: relative;
   
}

.banner-container {
    width: 100%;
    height:auto; /* Set a fixed height for the banner container */
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
}

.banner-wrapper {
    display: flex;
    width: 300%; /* Adjust width based on the number of banner images */
    animation: scrollBanner 15s linear infinite;
}

.slide {
    margin: 0; /* Remove any margin around the slides */
}

.banner-image {
    width: 100vw; /* Adjust based on your image size */
    height: 100%; /* Ensures images fill the container height */
    object-fit: cover; /* Ensures images cover the container without distortion */
    display: block; /* Ensures the image takes the full width and has no inline gaps */
}

@keyframes scrollBanner {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-66.66%); /* Adjust based on number of images and width */
    }
}

.navbar {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; /* Ensure the NavBar is on top of the banner */
}

.hrcwa-section {
    margin-top: 40px;
}

.hrcwa-title {
    font-size: 24px;
    font-weight: bold;
    color: #d14327; /* Matching the red header in the screenshot */
    padding: 10px 0;
    background-color: #fff1e6;
}

.hrcwa-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.hrcwa-image {
    max-width: 80%;
    height: auto;
    margin-top: 10px;
}
.banner-container {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
}

.banner-image {
    width: 100%; /* Ensures images fill the width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure images cover the container without distortion */
}

@media (max-width: 768px) {
    .banner-container {
        height: 200px; /* Set a fixed height for smaller screens if necessary */
    }
    
    .banner-image {
        height: auto; /* Allow images to resize proportionally */
    }
}

.slick-initialized .slick-slide {
    display: block;
    margin: 0;
    padding:0;
}