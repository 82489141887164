
.margin-custom{
  margin-top: 100px;

}


/* NavBar.css */
.navbar {
  transition: background-color 0.3s ease;
}

.navbar-custom {
  background-color: #f8f9fa;
  border-bottom: 2px solid #e0e0e0;
  position: fixed;
}

.custom-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 50px;
  width: 100px;
}

.navbar-toggler-icon {
  color: #333;
}

.custom-nav-link {
  font-size: 18px;
  font-weight: bold;
  color: #555;
}

.custom-nav-link.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.nav-item:hover .custom-nav-link {
  color: #0056b3;
}
@media (min-width: 992px) {
  .bg-transparent-lg {
    background-color: transparent !important;
  }
}